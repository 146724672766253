<template>
  <div class="">
    <eden-page-header :title="'Schedule'"> </eden-page-header>
    <eden-page-stats
      :stats="stat"
      title="Overview"
      :show-periods="true"
      :periods="periods"
      :default-period="defaultPeriod"
      @set-period="getStats"
    >
    </eden-page-stats>

    <el-tabs v-model="tab" @tab-click="updateRoute">
      <el-tab-pane
        v-for="eachTab in tabs"
        :label="eachTab.label"
        :name="eachTab.name"
        :key="eachTab.name"
        @click="() => updateRoute(eachTab)"
      >
        <component :is="'OrdersAndScheduleTable'" :tag="eachTab" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import OrdersAndScheduleTable from "@/components/OrdersAndSchedule/OrdersAndScheduleTable.vue";
import * as actions from "@/store/action-types";
import orders from "@/requests/orders/marketplace.js";

export default {
  name: "Products",

  components: {
    OrdersAndScheduleTable,
  },

  data() {
    return {
      tab: "all",
      loading: false,
      data: [],
      periods: {
        alltime: "All time",
        today: "Today",
        lastweek: "This week",
        thismonth: "This month",
      },
      defaultPeriod: "alltime",
    };
  },

  computed: {
    tabs() {
      let businessTypes = this.$store.getters.business_types.map((type) => ({
        name: type.name.toLowerCase(),
        label: type.name,
        id: type.id,
      }));

      // Add an 'all' tab to the beginning of the array
      businessTypes.unshift({ name: "all", label: "All", id: "" });

      return businessTypes;
    },

    stat() {
      return this.data.map((item) => ({
        label: this.formatName(this.formatStat(item.stat_key)),
        figure: item.stat_value,
      }));
    },
    businessTypes() {
      return this.$store.getters.business_types;
    },
  },

  created() {
    let { tab } = this.$route.query;
    this.tab = tab ? tab : "all";
    if (!tab) {
      this.$router.push({
        name: "ordersAndSchedule.index",
        query: { tab: "all" },
      });
    }
    this.getBusinessType();
    this.getStats({ period: "alltime" });
  },

  methods: {
    formatStat(statKey) {
      return statKey.replace(/_/g, " ");
    },
    getBusinessType() {
      if (!this.businessTypes.length) {
        this.loading = true;
        this.$store.dispatch(actions.GET_BUSINESS_TYPE).then(() => {
          this.businessType = this.businessTypes;
          this.loading = false;
        });
      }
    },

    getStats({ period, from, to }) {
      this.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      let params;
      if (period === "alltime") {
        params = {
          status: "all",
        };
      } else {
        params = {
          status: "all",
          from_date: start_date,
          to_date: end_date,
        };
      }
      orders
        .stats(params)
        .then((response) => {
          if (response.data.status) {
            this.data = response.data.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.loading = false;
        });
    },

    updateRoute(tab) {
      const currentTab = this.$route.query.tab;
      if (currentTab === this.tab) {
        return false;
      }
      this.$router.push({
        name: "ordersAndSchedule.index",
        query: { tab: tab.name },
      });
    },
  },
};
</script>
