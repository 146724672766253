<template>
  <div>
    <eden-table-actions
      placeholder="Search"
      :title="title"
      @search="search"
      show-search
    >
      <template #actions>
        <div
          v-if="multipleOrders.length >= 1"
          class="mr-10 is-flex gap-1 is-align-center"
        >
          <div>
            {{ multipleOrders.length + " orders selected" }}
          </div>
          <el-button
            @click="addOrders"
            type="primary"
            :disabled="loading || !customOrders?.length"
          >
            Mark as Complete
          </el-button>
          <el-button
            @click="logisticsRequest"
            type="primary"
            :disabled="loading || !customOrders?.length"
          >
            Make logistic request
          </el-button>
          <el-button
            @click="cancelOrders"
            type="primary"
            :disabled="loading || !customOrders?.length"
          >
            Cancel Order
          </el-button>
        </div>
        <order-filter
          :disabled="loading"
          :clear="filterParams.clear"
          @filter="filter"
          class="mr-2"
        />
        <orders-export :data="customOrders" />
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <eden-filter-items
        class="ml-auto"
        v-if="filterParams.status"
        :params="filterParams.paramsLabel"
        @clear-filter="clearFilter"
        @clear-filters="clearFilters"
      />
      <el-table
        :data="customOrders"
        v-if="customOrders.length"
        :key="sort.property"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column width="150">
          <template #header>
            <eden-table-column-header
              :label="'Order ID'"
              :property="'order_id'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'order_id')"
            />
          </template>
          <template v-slot="scope">
            <router-link
              :to="{
                name: 'ordersAndSchedule.details',
                params: { id: scope.row.uuid },
              }"
            >
              <span class="font-sm text-primary">
                #{{ scope.row.order_number }}</span
              ></router-link
            >
          </template>
        </el-table-column>

        <el-table-column width="200">
          <template #header>
            <eden-table-column-header
              :label="'Customer'"
              :property="'customer_name'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'customer_name')"
            />
          </template>
          <template v-slot="scope">
            {{ formatName(scope.row.customer_name) ?? "-" }}
          </template>
        </el-table-column>

        <el-table-column width="200">
          <template #header>
            <eden-table-column-header
              :label="'Order'"
              :property="'items'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'items')"
            />
          </template>
          <template v-slot="scope">
            <div class="wrapper-overflow mr-2">
              {{ customerOrder(scope.row.items) }}
            </div>
          </template>
        </el-table-column>

        <el-table-column width="150">
          <template #header>
            <eden-table-column-header
              :label="'Vendor Name'"
              :property="'vendor_name'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'vendor_name')"
            />
          </template>
          <template v-slot="scope">
            {{ scope.row.vendor_name ?? "-" }}
          </template>
        </el-table-column>

        <el-table-column width="150">
          <template #header>
            <eden-table-column-header
              :label="'Delivery Date'"
              :property="'delivery_date'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'delivery_date')"
            />
          </template>
          <template v-slot="scope">
            {{ formatDate(scope.row.delivery_date, "do m, y") }}
          </template>
        </el-table-column>

        <el-table-column width="200">
          <template #header>
            <eden-table-column-header
              :label="'Status'"
              :property="'status'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'status')"
            />
          </template>
          <template v-slot="scope">
            <el-tag :type="setType(scope.row.status)">{{
              scope.row.status
            }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column width="150">
          <template #header>
            <eden-table-column-header
              :label="'Order Date'"
              :property="'order_date'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'order_date')"
            />
          </template>
          <template v-slot="scope">
            {{ formatDate(scope.row.order_date, "do m, y") }}
          </template>
        </el-table-column>

        <el-table-column width="100">
          <template #header>
            <eden-table-column-header
              :label="'Order Type'"
              :property="'order_type'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'order_type')"
            />
          </template>
          <template v-slot="scope">
            {{
              scope.row.delivery_window === "on_demand"
                ? "On-Demand"
                : "Pre-Order"
            }}
          </template>
        </el-table-column>

        <el-table-column width="150">
          <template #header>
            <eden-table-column-header
              :label="'Account Manager'"
              :property="'account_manager'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'account_manager')"
            />
          </template>
          <template v-slot="scope">
            {{ scope.row.account_manager }}
          </template>
        </el-table-column>

        <el-table-column width="150">
          <template #header>
            <eden-table-column-header
              :label="'Delivery Partner'"
              :property="'logistics_partner'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'logistics_partner')"
            />
          </template>
          <template v-slot="scope">
            {{ formatName(scope.row.logistics_partner) }}
          </template>
        </el-table-column>

        <el-table-column width="150">
          <template #header>
            <eden-table-column-header
              :label="'Order Placed Time'"
              :property="'order_date'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'order_date')"
            />
          </template>
          <template v-slot="scope">
            {{ formatTime(scope.row.order_date, "12h") }}
          </template>
        </el-table-column>

        <el-table-column width="250">
          <template #header>
            <eden-table-column-header
              :label="'Acceptance//Cancellation Time'"
              :property="'accepted_at'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'accepted_at')"
            />
          </template>
          <template v-slot="scope">
            {{
              scope.row.accepted_at
                ? formatTime(scope.row.accepted_at, "12h")
                : formatTime(scope.row.cancelled_at, "12h")
            }}
          </template>
        </el-table-column>
        <el-table-column width="150">
          <template #header>
            <eden-table-column-header
              :label="'Pickup Time'"
              :property="'dispatched_at'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'dispatched_at')"
            />
          </template>
          <template v-slot="scope">
            {{ formatTime(scope.row.dispatched_at, "12h") }}
          </template>
        </el-table-column>
        <el-table-column width="150">
          <template #header>
            <eden-table-column-header
              :label="'Delivery Time'"
              :property="'completed_at'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'completed_at')"
            />
          </template>
          <template v-slot="scope">
            {{ formatTime(scope.row.completed_at, "12h") }}
          </template>
        </el-table-column>
      </el-table>
      <eden-content-empty v-else :text="'No Orders'" />
      <eden-pagination
        v-if="customOrders.length"
        :current-page.sync="page"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :from="pagination.from"
        :to="pagination.to"
      />
    </template>
  </div>
</template>

<script>
import orders from "@/requests/orders/marketplace.js";
import OrderFilter from "./OrderFilter.vue";
import OrdersExport from "./OrdersExport.vue";

export default {
  name: "OrdersAndScheduleTable",
  components: {
    OrderFilter,
    OrdersExport,
  },

  props: {
    tag: {
      type: Object,
      default: () => ({}), // This will not cause a linting error
    },
  },

  data() {
    return {
      loading: false,
      customOrders: [],
      searchQuery: "",
      page: 1,
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      pagination: {
        from: null,
        to: null,
        total: null,
      },
      sort: {
        property: "order_id",
        direction: "asc",
      },
      multipleOrders: [],
    };
  },

  watch: {
    tag: "getOrders",
    page() {
      // Check if filters are applied
      if (this.filterParams.status) {
        // Filters are applied. Call the filter method
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        // No filters applied. Call the getOrders method
        this.getOrders();
      }
    },
  },

  computed: {
    title() {
      const customOrderCount = this.pagination.total || 0;
      const tagName =
        this.tag.name.charAt(0).toUpperCase() +
        this.tag.name.slice(1).toLowerCase();
      const title = `${customOrderCount} ${tagName} Orders`;
      return title;
    },
  },
  created() {
    this.getOrders();
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleOrders = val;
    },

    search(query) {
      this.searchQuery = query;
      this.page = 1; // Reset page to 1 when a new search is made
      this.getOrders();
    },

    addOrders() {
      this.loading = true;
      let payload = {
        order_uuids: this.multipleOrders.map((item) => item.uuid),
      };

      const orderStatus = this.multipleOrders.map((item) => item.status);
      if (orderStatus.includes("Cancelled")) {
        this.$message.error(
          "Orders with status cancelled cannot be marked as completed",
        );
      }
      orders
        .complete(payload)
        .then((response) => {
          this.$message.success(response.data.message);
          this.getOrders();
          this.multipleOrders = [];
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    logisticsRequest() {
      this.loading = true;
      let payload = {
        order_uuids: this.multipleOrders.map((item) => item.uuid),
      };
      orders
        .logistics(payload)
        .then((response) => {
          this.$message.success(response.data.message);
          this.getOrders();
          this.multipleOrders = [];
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    cancelOrders() {
      this.loading = true;
      let payload = {
        order_uuids: this.multipleOrders.map((item) => item.uuid),
      };
      orders
        .cancel(payload)
        .then((response) => {
          this.$message.success(response.data.message);
          this.getOrders();
          this.multipleOrders = [];
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },

    fetchOrders(params) {
      orders
        .list(params)
        .then(this.handleOrderResponse)
        .catch(this.handleError);
    },

    handleOrderResponse({ data }) {
      const { status, meta, data: orderData } = data;
      if (status) {
        this.customOrders = orderData;
        this.pagination = {
          from: meta.from,
          to: meta.to,
          total: meta.total,
          pageSize: meta.per_page,
        };
        this.loading = false;
      }
    },

    getOrders() {
      this.loading = true;
      let params = `?business_type_id=${this.tag.id}&status=all&page=${this.page}`;
      if (this.searchQuery) {
        params += `&search=${this.searchQuery}`;
      }
      this.fetchOrders(params);
    },

    handleError(error) {
      this.loading = false;
      const errorMessage = error.response?.data;

      if (errorMessage.errors) {
        const errorKeys = Object.keys(errorMessage.errors);
        this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
      } else {
        this.$message.error(errorMessage.message);
      }
    },

    customerOrder(orderItems) {
      const MAX_LENGTH = 25;
      if (!Array.isArray(orderItems)) {
        return "";
      }
      let names = orderItems.map(
        (item) => `${item.quantity} ${this.formatMealName(item.item.name)}`,
      );
      let str =
        names.length > 1
          ? `${names.slice(0, -1).join(", ")} and ${names[names.length - 1]}`
          : names[0];

      if (str && str.length > MAX_LENGTH) {
        str = str.substring(0, MAX_LENGTH - 3) + "...";
      }

      return str;
    },
    sortPageData(direction, property) {
      this.sort.property = property;
      this.sort.direction = direction;
      this.customOrders = this.sortList(this.customOrders, property, direction);
    },

    filter({ params, paramsLabel }) {
      this.loading = true;
      // this.page = 1;
      const constParams = `business_type_id=${this.tag.id}&page=${this.page}`;
      let stringedParams;
      if (Object.keys(params).length) {
        stringedParams = `${constParams}&${this.sortObjectAsParams(params)}`;
      } else {
        stringedParams = constParams;
      }
      this.stringedParams = stringedParams;
      orders
        .filter(this.stringedParams)
        .then((response) => {
          const { status, meta, data } = response.data;
          if (status) {
            this.filterParams = {
              status: true,
              params: params,
              paramsLabel: paramsLabel,
            };
            this.customOrders = data;
            // this.page = 1;
            this.pagination = {
              from: meta.from,
              to: meta.to,
              total: meta.total,
              pageSize: meta.per_page,
            };
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];

      const keys = this.filterParams.params;

      if (Object.keys(keys).length) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.getOrders();
      }
    },
    clearFilters() {
      this.getOrders();
      this.filterParams = {
        clear: true,
        status: false,
        params: {},
        paramsLabel: {},
      };
    },
  },
};
</script>
