<template>
  <div>
    <el-button
      type="plain"
      icon="eden-icon-upload"
      :disabled="loading || !data.length"
      @click="showExport = true"
    >
      Export
    </el-button>
    <el-dialog
      title="Export"
      :visible.sync="showExport"
      @close="closeEvent"
      width="30%"
    >
      <el-form label-position="top" :model="form">
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item label="Select the data you'd like to export">
              <el-checkbox-group v-model="form.fields">
                <el-checkbox
                  v-for="(value, key, i) in fields"
                  :key="i"
                  :disabled="
                    key === 'order_id' ||
                    key === 'customer_name' ||
                    key === 'vendor_name'
                  "
                  :label="key"
                >
                  {{ value }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item label="Select date range to export">
              <eden-periods
                :custom-periods="periods"
                :show-custom-period="true"
                @set-period="getTimeline"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item label="Select data format">
              <el-radio-group v-model="form.format">
                <el-radio
                  v-for="(key, value, i) in formats"
                  :key="i"
                  :label="value"
                >
                  {{ key }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="is-flex justify-end">
        <el-button
          type="primary"
          :loading="exporting"
          @click="exportAction"
          :disabled="!form.format || !form.fields.length"
          >Export</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import orders from "@/requests/orders/marketplace.js";
export default {
  name: "DirectoryRidersExport",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      showExport: false,
      fields: {
        order_id: "Order Id",
        customer_name: "Customer",
        vendor_name: "Vendor name",
        order_items: "Order",
        delivery_date: "Delivery Date",
        order_status: "Status",
        order_date: "Order date",
        order_type: "Order Type",
        account_manager: "Account Manager",
        acceptance_time: "Acceptance Time",
        cancellation_time: "Cancellation Time",
        pickup_time: "Pickup Time",
        delivery_time: "Delivery Time",
      },
      formats: {
        pdf: "PDF",
        csv: "CSV",
        xlsx: "Excel",
      },
      form: {
        fields: ["order_id", "customer_name", "vendor_name"],
        format: "",
        start_date: null,
        end_date: null,
      },
      periods: {
        alltime: "All time",
        today: "Today",
        thisweek: "This week",
        thismonth: "This month",
      },
      exportData: {},
      exporting: false,
      period: null,
    };
  },
  watch: {
    exporting() {
      if (!this.exporting) {
        this.closeEvent();
      }
    },
  },
  created() {
    this.getTimeline({ period: "today" });
  },
  methods: {
    closeEvent() {
      this.form.fields = ["order_id", "customer_name", "vendor_name"];
      this.form.format = "";
      this.exporting = false;
      this.showExport = false;
    },

    getTimeline({ period, from, to }) {
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      this.form.end_date = end_date;
      this.form.start_date = start_date;
      this.period = period;
    },

    exportAction() {
      this.exporting = true;
      let payload;
      if (this.form.start_date && this.form.end_date) {
        payload = {
          export_all_pages: this.period === "alltime" ? true : false,
          export_format: this.form.format,
          start_date: this.formatDate(this.form.start_date, "y-m-d"),
          end_date: this.formatDate(this.form.end_date, "y-m-d"),
          export_headers: this.form.fields,
        };
      } else {
        payload = {
          export_all_pages: false,
          export_format: this.form.format,
          export_headers: this.form.fields,
        };
      }
      orders
        .export(payload)
        .then((response) => {
          this.exporting = false;
          this.$message.success(response.data.message);
        })
        .catch((error) => {
          this.exporting = false;
          this.$message.error(error.response.data.message);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.downloadComboCsv,
.downloadComboXls {
  position: absolute;
  opacity: 0;
}
</style>
