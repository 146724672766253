<template>
  <eden-filter
    :width="270"
    :properties.sync="properties"
    :disabled="disabled"
    :loading="loading"
    @open="getData"
    @reset="reset"
    @filter="filter"
  />
</template>

<script>
import * as actions from "@/store/action-types";
export default {
  name: "OrderFilter",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      properties: {
        date: {
          key: ["from_date", "to_date"],
          label: "Delivery Date",
          type: "daterange",
          value: [],
        },
        delivery_window: {
          key: "delivery_window",
          label: "Delivery Window",
          type: "list",
          options: [
            {
              label: "Pre Order",
              value: "pre_order",
            },
            {
              label: "On Demand",
              value: "on_demand",
            },
          ],
          value: [],
          searchable: true,
        },
        order_date: {
          key: ["order_start_date", "order_end_date"],
          label: "Order Date",
          type: "daterange",
          value: [],
        },
        status: {
          key: "status",
          label: "Status",
          type: "list",
          options: [
            {
              label: "Pending",
              value: "pending",
            },
            {
              label: "Accepted",
              value: "accepted",
            },
            {
              label: "Prepared",
              value: "prepared",
            },
            {
              label: "Dispatched",
              value: "dispatched",
            },
            {
              label: "Completed",
              value: "completed",
            },
            {
              label: "Enroute pickup",
              value: "enroute-pickup",
            },
            {
              label: "Rejected",
              value: "rejected",
            },
            {
              label: "Cancelled",
              value: "cancelled",
            },
            {
              label: "Pending Payment Confirmation",
              value: "pending-payment-confirmation",
            },
          ],
          value: [],
          searchable: true,
        },
        accountManager: {
          key: "account_manager_id",
          label: "Account Manager",
          type: "list",
          options: [],
          value: [],
          searchable: true,
        },
      },
    };
  },
  computed: {
    accountManagers() {
      return this.$store.getters.account_managers;
    },
  },
  watch: {
    clear() {
      if (this.clear) {
        this.reset();
      }
    },
  },
  methods: {
    getData() {
      this.getAccountManagers();
    },
    getAccountManagers() {
      if (this.properties.accountManager.options.length) {
        this.loading = false;
        return;
      }
      this.accountManagers.length
        ? this.setAccountManagersOptions()
        : this.$store.dispatch(actions.GET_ACCOUNT_MANAGERS_LIST).then(() => {
            this.setAccountManagersOptions();
          });
    },
    setAccountManagersOptions() {
      this.properties.accountManager.options = this.accountManagers.map(
        (accountManager) => {
          return {
            label: this.formatText(accountManager.name),
            value: accountManager.id,
          };
        },
      );
      this.loading = false;
    },
    reset() {
      Object.keys(this.properties).forEach((key) => {
        this.properties[key].value = [];
      });
    },
    filter() {
      let params = {
        params: {},
        paramsLabel: {},
      };

      Object.keys(this.properties).forEach((property) => {
        const { key, type, value } = this.properties[property];
        if (value && value.length) {
          switch (type) {
            case "list":
              params.params[key] = value[0];
              params.paramsLabel[key] = this.properties[property];
              break;
            case "list-multiple":
              params.params[key] = value;
              params.paramsLabel[key] = this.properties[property];
              break;
            case "daterange":
              params.params[key[0]] = this.properties[property].value[0];
              params.params[key[1]] = this.properties[property].value[1];
              params.paramsLabel[key] = this.properties[property];
              break;
            default:
              break;
          }
        }
      });
      if (Object.keys(params.params).length) {
        this.$emit("filter", {
          params: params.params,
          paramsLabel: params.paramsLabel,
        });
      } else {
        this.$message.info("Filtering requires at least one property");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
